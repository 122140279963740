
import React from 'react'

import "./LiveS.css";

import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import Footer from '../Footer/Footer ';
const LiveS = () => {

  return (
    <div>

    <div className="body-fix-h 
   ">
      <div className=' bg-stream'>
    
      <div className="live-s-f wrapper">
        <div>
          <Sidebar1 />
        </div>
<div className='grid-c1 main-y'>

        <div className="live-head-1  grid-c2">
          <div className="live-head-2">
            <div className="live-head-3">
              <div className="live-head-h">
                <h1>Live</h1>
              </div>
              <div className="live-head-p">
                <p>
               Starting soon
                </p>
              </div>
            </div>
          </div>

        </div>
       <div className="grid-c3">
              <Footer />
            </div>
</div>

      </div>
    </div>
    </div>
    
    </div>
  );
};

export default LiveS;
