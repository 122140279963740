/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useContext, useState } from "react";
import "./Modules.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer ";
import { useEffect } from "react";
import {  doc, onSnapshot } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";

import { AuthContext } from "../../../context/AuthContext";
import { MdPlayLesson } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";
import { toast } from "react-hot-toast";

const Modules = () => {
  const { user } = useContext(AuthContext);

  const [getUser, setGetUser] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "usersLesson", user?.uid), (doc) => {
      setGetUser(doc.data());
    });

    return () => unsub();
  }, [user?.uid]);

  const navigate = useNavigate();
  return (
    <div className="bg-module">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="module-div">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y modules11 grid-c1">
              <div className="grid-c2">
                <div className="module-a1 ">
                  <div>
                    <div className="module-a2">
                      <div className="module-a3">
                        <h1 className="module-h1">
                       Tuff Trainer Certification
                        </h1>
                        <h1 className="module-h2">View Modules details </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="module-a4">
                  <div className="section-1-module">
                    <div className="module-a5">
                      {/*  */}
                      <div>
                        <div className="module-a6">
                          <div>
                            <h1 className="module-h3">
                              {getUser?.Module1}% Complete
                            </h1>
                            <h1 className="module-h4">
                              Last activity on 1 Feburary 2022
                            </h1>
                          </div>
                          <div class="progress">
                            {getUser?.Module1 === 25 ? (
                              <div class="progress-value1"></div>
                            ) : (
                              <>
                                {getUser?.Module1 === 50 ? (
                                  <div class="progress-value2"></div>
                                ) : (
                                  <>
                                    {getUser?.Module1 === 75 ? (
                                      <div class="progress-value3"></div>
                                    ) : (
                                      <>
                                        {getUser?.Module1 === 100 ? (
                                          <div class="progress-value4"></div>
                                        ) : (
                                          <>
                                            <div class="progress-value"></div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="module-a7">
                        <div className="module-a8">
                          <p>
                          The main objective of module 1 is a basic intoduction to our VISION.
The first and second chapter basically coveres WHY WE STARTED and WHAT’S YOUR PURPOSE. So that it can refine your vision about the expectations and realities.{" "}
                          </p>
                        </div>
                        <div className="module-a9">
                          <p>
                          Next chapters ANALYSING THE PHYSIQUE AND GOAL SETTING & BULK OR CUT , are specially designed to clear the very first step of CLIENT ASSESMENT AND MAKING YOUR DECISIONS. {" "}
                          </p>
                        </div>
                        <div className="module-a10">
                          <p>
                          MAKE SURE TO TAKE FULL ADVANTAGE OF THE VAST COVERAGE OF ALL THE TOPICS.{" "}
                          </p>
                        </div>
                        {/* <div className="module-a11">
                          <p>
                            Requirements: Basic understanding of competitive
                            physique sport and a beginner understanding of
                            nutrition, training, and supplements.
                          </p>
                        </div>
                        <div className="module-a12">
                          <p>
                            The Modules will be continued to be updated as new
                            evidence and questions arise.
                          </p>
                        </div> */}
                      </div>
                      {/*  */}
                      <div>
                        <div>
                          <h1 className="module-h5">MODULES CONTENT</h1>
                        </div>
                        {/* Module 1 */}
                        <div>
                          <div>
                            <h1 className="module-h6">
                              MODULE 1 - INTRODUCTION
                            </h1>
                          </div>

                          <div className="modules-title">
                          
                            {getUser?.Module1 >= 25 ? (
                              <div
                                onClick={() =>
                                  navigate("/Modules/Lessons", {
                                    state: { id: "Chapter1" },
                                  })
                                }
                                className="module-a13"
                              >
                                <div>
                                  <h1 className="module-h8">WHY WE STARTED</h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#0072BB" }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  navigate("/Modules/Lessons", {
                                    state: { id: "Chapter1" },
                                  })
                                }
                                className="module-a13"
                              >
                                <div>
                                  <h1 className="module-h8 strikethroughh1">
                                    WHY WE STARTED
                                  </h1>
                                </div>
                              </div>
                            )}
  {getUser?.Module1 >= 50 ? (
    <div
    onClick={() =>
      navigate("/Modules/Lessons", {
        state: { id: "Chapter2" },
      })
    }
    className="module-a14"
  >
    <div>
      <h1 className="module-h8">
        WHAT's YOUR PURPOSE
      </h1>
    </div>
    <div>

<AiFillCheckCircle
size={28}
style={{ color: "#0072BB" }}
/>
</div>
  </div>
  ) : (
    <div
    onClick={() =>
      navigate("/Modules/Lessons", {
        state: { id: "Chapter2" },
      })
    }
    className="module-a14"
  >
    <div>
      <h1 className="module-h8">
        WHAT's YOUR PURPOSE
      </h1>
    </div>
  </div>
  )}
                        {getUser?.Module1 >= 75 ? (
   <div
   onClick={() =>
     navigate("/Modules/Lessons", {
       state: { id: "Chapter3" },
     })
   }
   className="module-a14"
 >
   <div>
     <h1 className="module-h8">
       ANALYSING THE PHYSIQUE{" "}
     </h1>
   </div>
   <div>

<AiFillCheckCircle
size={28}
style={{ color: "#0072BB" }}
/>
</div>
 </div>
                        ) : (
                          <div
                          onClick={() =>
                            navigate("/Modules/Lessons", {
                              state: { id: "Chapter3" },
                            })
                          }
                          className="module-a14"
                        >
                          <div>
                            <h1 className="module-h8">
                            ANALYSING THE PHYSIQUE{" "}
                            </h1>
                          </div>
                
                        </div>
                        )}

{getUser?.Module1 === 100 ? (
   <div
   onClick={() =>
     navigate("/Modules/Lessons", {
       state: { id: "Chapter4" },
     })
   }
   className="module-a14"
 >
   <div>
     <h1 className="module-h8">
       TAKING THE RIGHT STEP (bulk, cut and
       maintenance )
     </h1>
   </div>
   <div>

<AiFillCheckCircle
size={28}
style={{ color: "#0072BB" }}
/>
</div>
 </div>
) : (
  <div
  onClick={() =>
    navigate("/Modules/Lessons", {
      state: { id: "Chapter4" },
    })
  }
  className="module-a14"
>
  <div>
    <h1 className="module-h8">
      TAKING THE RIGHT STEP (bulk, cut and
      maintenance )
    </h1>
  </div>

</div>
)}
                         
                         
                          </div>
                        </div>
                           {/* Module 2 */}
                           <div>
                          <div>
                            <h1 className="module-h6">
                              MODULE 2 - COMING SOON
                            </h1>
                          </div>

                          {/* <div className="modules-title">
                          
                            {getUser?.Module1 >= 25 ? (
                              <div
                                onClick={() =>
                                  navigate("/Modules/Lessons", {
                                    state: { id: "Chapter1" },
                                  })
                                }
                                className="module-a13"
                              >
                                <div>
                                  <h1 className="module-h8">WHY WE STARTED</h1>
                                </div>
                                <div>
                                  <AiFillCheckCircle
                                    size={28}
                                    style={{ color: "#0072BB" }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  navigate("/Modules/Lessons", {
                                    state: { id: "Chapter1" },
                                  })
                                }
                                className="module-a13"
                              >
                                <div>
                                  <h1 className="module-h8 strikethroughh1">
                                    WHY WE STARTED
                                  </h1>
                                </div>
                              </div>
                            )}
  {getUser?.Module1 >= 50 ? (
    <div
    onClick={() =>
      navigate("/Modules/Lessons", {
        state: { id: "Chapter2" },
      })
    }
    className="module-a14"
  >
    <div>
      <h1 className="module-h8">
        WHAT's YOUR PURPOSE
      </h1>
    </div>
    <div>

<AiFillCheckCircle
size={28}
style={{ color: "#0072BB" }}
/>
</div>
  </div>
  ) : (
    <div
    onClick={() =>
      navigate("/Modules/Lessons", {
        state: { id: "Chapter2" },
      })
    }
    className="module-a14"
  >
    <div>
      <h1 className="module-h8">
        WHAT's YOUR PURPOSE
      </h1>
    </div>
  </div>
  )}
                        {getUser?.Module1 >= 75 ? (
   <div
   onClick={() =>
     navigate("/Modules/Lessons", {
       state: { id: "Chapter3" },
     })
   }
   className="module-a14"
 >
   <div>
     <h1 className="module-h8">
       PHYSIQUE ASSESSMENT{" "}
     </h1>
   </div>
   <div>

<AiFillCheckCircle
size={28}
style={{ color: "#0072BB" }}
/>
</div>
 </div>
                        ) : (
                          <div
                          onClick={() =>
                            navigate("/Modules/Lessons", {
                              state: { id: "Chapter3" },
                            })
                          }
                          className="module-a14"
                        >
                          <div>
                            <h1 className="module-h8">
                              PHYSIQUE ASSESSMENT{" "}
                            </h1>
                          </div>
                
                        </div>
                        )}

{getUser?.Module1 === 100 ? (
   <div
   onClick={() =>
     navigate("/Modules/Lessons", {
       state: { id: "Chapter4" },
     })
   }
   className="module-a14"
 >
   <div>
     <h1 className="module-h8">
       TAKING THE RIGHT STEP (bulk, cut and
       maintenance )
     </h1>
   </div>
   <div>

<AiFillCheckCircle
size={28}
style={{ color: "#0072BB" }}
/>
</div>
 </div>
) : (
  <div
  onClick={() =>
    navigate("/Modules/Lessons", {
      state: { id: "Chapter4" },
    })
  }
  className="module-a14"
>
  <div>
    <h1 className="module-h8">
      TAKING THE RIGHT STEP (bulk, cut and
      maintenance )
    </h1>
  </div>

</div>
)}
                         
                         
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="module-a020">
                      <div className="module-a20">
                        <div class="card-SideMoudle">
                       
                          <img
                            src={require("./../../Img/Artboard3.jpg")}
                            alt="Image"
                          />
                          <div class="card-description">
                            <div className="card-description-d1">
                              <h1 className="module-h15">In Progress</h1>
                              {getUser?.Module1 === 0 ? (
                                <>
                              <button  onClick={() =>
                                navigate("/Modules/Lessons", {
                                  state: { id: "Chapter1" },
                                })
                              }  className="btn-1-Modules">
                                Continue
                              </button>
                                </>
                                
                              ) : (
<>
{getUser?.Module1 === 25 ? (
                                <>
                              <button  onClick={() =>
                                navigate("/Modules/Lessons", {
                                  state: { id: "Chapter2" },
                                })
                              }  className="btn-1-Modules">
                                Continue
                              </button>
                                </>
                                
                              ) : (
<>
{getUser?.Module1 === 50 ? (
                                <>
                              <button  onClick={() =>
                                navigate("/Modules/Lessons", {
                                  state: { id: "Chapter3" },
                                })
                              }  className="btn-1-Modules">
                                Continue
                              </button>
                                </>
                                
                              ) : (
<>
{getUser?.Module1 === 75 ? (
                                <>
                              <button  onClick={() =>
                                navigate("/Modules/Lessons", {
                                  state: { id: "Chapter4" },
                                })
                              }  className="btn-1-Modules">
                                Continue
                              </button>
                                </>
                                
                              ) : (
<>
<button  onClick={() =>
                                toast.success('Congrats! You Completed Module 1 , Module 2 will uploaded soon.')
                              }  className="btn-1-Modules">
                                Continue
                              </button>
</>
                              )}
</>
                              )}
</>
                              )}
</>
                              )}
                            </div>
                            <div className="card-description-d2">
                              <h1 className="dec-h1">Module 4</h1>
                              <div className="card-description-d3">
                                <h1 className="dec-h2">
                                  <span className="span-cer1">
                                    <MdPlayLesson size={25} />4 Lessons
                                  </span>
                                </h1>
                                <h1 className="dec-h2">
                                  <span className="span-cer1">
                                    <GrCertificate size={25} />
                                    Certification
                                  </span>
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-c3">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modules;
