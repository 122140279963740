import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';

const Verification = () => {

    const { user,  } = useContext(AuthContext);
    const navigate = useNavigate()
    useEffect(()=> {
       if(user){
navigate('/Home')
       } else if (!user) {
        navigate('/')
       }
    })
  return (
 <>
 </>
  )
}

export default Verification