import React from "react";
import Sidebar2 from "../../Features/Sidebar/Sidebar2";
import Footer from "../Footer/Footer ";

const Aboutus2 = () => {
  return (
    <div className='bg-about'>
    <div class="Body-11">
<div className=" body-fix-h">
<div className="wrapper ">
<div className="sidebar-home-d">
    <Sidebar2/>
  </div>
  <div className='main-y grid-c1'>

  <div className="head-about  grid-c2 ">
    <div className="HeadAboutBody">
    <h1 className="about-a-h1">About Us</h1>

      <div className="w-o">
        <div className="w-oo">
        <div>
                        <p className="about-pp2">
                          CONTEST PREP ACADEMY is not just simply an educational
                          course. Yes, it provides that too but CPA is a
                          educational community where you can study all the
                          basic and advanced aspects of bodybuilding, but you
                          can interact with all CPA members and can share your
                          experiences with each other.
                        </p>
                        <p className="about-pp2">
                          With a deep coverage of BODYBUILDING BASICS i.e
                          Nutrition, Training, Recovery, PED’s, Peak week,
                          Getting show ready, Post show management, Pct and
                          countless other topics we decided to make ONE OF THE
                          MOST ELABORATED COURSE EVER.
                        </p>
                        <p className="about-pp2">
                          FACTS and PRACTICAL APPLICATION are the two most
                          important pillars of CONTEST PREP ACADEMY.
                        </p>
                        <p className="about-pp2">
                          40+ years of collective experience of CPA mentors is
                          going to unfold the whole new world of BODYBUILDING
                          for you.
                        </p>
                        <p className="about-pp2">
                          It does’nt matter if YOU ARE AN ATHLETE or a
                          BODYBUILDING COACH, CPA will help you in every aspect
                        </p>
                        <p className="about-pp2">
                          YOU CAN EVEN DO YOUR OWN PREP if you will study the
                          entire course very precisely.
                        </p>
                      </div>
        </div>
      </div>
    </div>
  </div>
    <div className="grid-c3">
      <Footer />
    </div>
  </div>

</div>
</div>
</div>
</div>
  );
};

export default Aboutus2;
