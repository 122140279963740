/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../Components/Features/Navbar/Navbar';
import Navbar2 from '../Components/Features/Navbar/Navbar2';
import ScrollToTop from '../Components/Scrollontop';
import { AuthContext } from '../context/AuthContext';

const Main = () => {
    const { user } = useContext(AuthContext);

    const navigate = useNavigate()
    useEffect(()=> {
        if(user){
            navigate('/Home')
        }
    },[user])


if(user) {



    return(
        <div>
        <Navbar></Navbar>
        <ScrollToTop/>
        <Outlet></Outlet>
        {/* <Footer></Footer> */}
    </div>
    )
} else {
    return(
        <div>
        <Navbar2></Navbar2>
        <ScrollToTop/>
        <Outlet></Outlet>
        {/* <Footer2></Footer2> */}
    </div>
    )
}

//     return (
// <>

       
// </>
//     );
};

export default Main;