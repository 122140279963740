import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineSend } from "react-icons/ai";

import Modal from "react-modal";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { FileUploader } from "react-drag-drop-files";
import "./Replydata.css";
import { toast } from "react-hot-toast";
import Footer from "../Footer/Footer ";
import { useNavigate, useParams } from "react-router-dom";
import { onSnapshot, doc, addDoc, collection, getDoc, updateDoc, query, orderBy, increment } from "firebase/firestore";
import { db, storage } from "../../Firebase/Firebase";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


const Replydata = () => {
  const { id } = useParams();
  const [forum, setForum] = useState();


  const { user } = useContext(AuthContext);
  const [getUser, setGetUser] = useState("");

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [user]);


  useEffect(() => {
    const unsub = onSnapshot(doc(db, "Forum", id), (doc) => {
      setForum(doc.data());
    });

    return () => {
      unsub();
    };
  }, [id]);


  const fileTypes = ["JPEG", "PNG"];
  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [name, setName] = useState('');
  const [tags, setTags] = useState('');
  const [forumdata, setForumData] = useState({});





  const addReply = async () => {
    if (file) {
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `forums/${new Date().getTime()} - ${file.name}`
        );
        try {
          const snap = await uploadBytes(imgRef, file);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await addDoc(collection(db, "Forum" , id , "reply"), {
            Image: url,
            reply: name,
            tags,
            user: getUser?.name,
            email: getUser?.email,
            createdAt: moment().format(),
            profileimg: getUser?.profileimg || ''
            
          })
          addDoc(collection(db, 'usersLesson' , forum.userId , 'Notification'),{
            Notification: `Someone Replied on Your Forum`,
            markasRead: false
          })
          updateDoc(doc(db, 'usersLesson' , forum.userId  ),{
            NotificationNumber: increment(1)
           })
            .then(() => {
              toast("Reply Sended Successfully", {
                duration: 2000,
                position: "top-center",
                icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              closeModal();
            })
            .then(() => {
            updateDoc(doc(db, "Forum" , id),{
              lastreply: moment().format(),
              replies: increment(1)
            })
              setName("");
              setFile();
              setTags("");
            });
        } catch (err) {
          console.log(err);
        }
      };
      uploadImg();
    } else {
      await addDoc(collection(db, "Forum" , id , "reply"), {
        reply: name,
        tags,
        user: getUser?.name,
        email: getUser?.email,
        createdAt: moment().format(),
        profileimg: getUser?.profileimg || ''
      })
      addDoc(collection(db, 'usersLesson' , forum.userId , 'Notification'),{
        Notification: `Someone Replied on Your Forum`,
        markasRead: false
      })
      updateDoc(doc(db, 'usersLesson' , forum.userId  ),{
        NotificationNumber: increment(1)
       })
        .then(() => {
          toast("Reply Sended Successfully", {
            duration: 2000,
            position: "top-center",
            icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          closeModal();
        })
        .then(() => {
          updateDoc(doc(db, "Forum" , id),{
            lastreply: moment().format(),
            replies: increment(1)
          })
          setName("");
          setFile();
          setTags("");
        });
    }
  };



useEffect(()=> {
  const q = query(collection(db, "Forum" , id , "reply"), orderBy("createdAt", "desc"));
const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const cities = [];
  querySnapshot.forEach((doc) => {
    cities.push({ id: doc.id, ...doc.data() });
  });
  setForumData(cities)
},[id]);

return() => {
  unsubscribe()
}
})

const navigate = useNavigate();




const [open, setOpen] = useState(false);





  return (
    <div className="reply-bg">
      <div className="body-fix-h ">
        <div className="wrapper ">
          <div className="side-reply">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y  grid-c1 ">
              <div className="data-reply  grid-c2 ">
                <div className="reply-data-s  ">
                  <div className="reply-main-data   ">
                    <div className="border-reply ">
                      <div>
                        <div className="head-reply-comment">
                          <div>
                            <button onClick={() => navigate(-1)} className="btn-head-comm">
                          Back
                            </button>
                            <h1 className="reply-h1">{forum?.name}</h1>
                            <div className="span-2-reply">
                              <span>Posted by</span>
                              <span className="name-hov"> {forum?.user}</span>
                              <span> on</span>
                              <span>
                                {" "}
                                {moment(forum?.createdAt).format(
                                  "MMMM Do YYYY , h:mm:ss a"
                                )}
                              </span>
                            </div>
                            <div className="para-1-reply">
                              <p >{forum?.description}</p>
                            </div>
                            {forum?.lastreply === '0' ? (
<>
</>
                            ): (
                              <div className="span-2-reply">
                              <span className="name-hov">Last Reply</span>
                              <span> {moment(forum?.lastreply).fromNow()}</span>
                          

                            
                            </div>
                            )}
                            {forum?.Image ? (
      <div className="forumimg">
      <img  onClick={() => setOpen(true)} className="forumimgimg" src={forum?.Image} alt="" />
    </div>
                            ): (
<>
</>
                            )}
                      
                         
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="reply-count reply-btn-count">
                        <h1>{forum?.replies} Replies</h1>
                        <div className="btn-side">
                          {forum?.verified === 'approved' ? (
  <button onClick={openModal} className="btn-reply-s">
  Reply
</button>
                          ) : (
<>
</>
                          )}
                        
                        </div>
                      </div>
                      <hr />
                      {forumdata.length === 0 ? (
                        <>
                        </>
                      ) : (
                        <>
                        {Array.isArray(forumdata)? forumdata.map((item,key)=> {
                          return (
                            <>
                            <div className="another-reply-1">
                            <div className="user-dp-time-member">
                              <div>
                                {item?.profileimg === '' ? (
     <img
     className="user-reply-dp"
     src={require("../../Img/user.png")}
     alt=""
   />
                                ) : (
                                  <img
                                  className="user-reply-dp"
                                  src={item?.profileimg}
                                  alt=""
                                />
                                )}
                           
                              </div>
                              <div className="user-data-t">
                                <h1>{item?.user}</h1>
                                <p>{moment(item?.createdAt).format(
                                  "MMMM Do YYYY , h:mm:ss a"
                                )}</p>
                              </div>
                            </div>
                            <div className="section-reply-p2">
                              <p className="wrp reply-para-uu">{item?.reply}</p>
                            </div>
                          </div>
                          <hr />
                          </>
                          )
                        })
                      : null
                      }
                
                        
                        </>
                      )}
                    
                    </div>
                    <div className="btn-side">
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-c3">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* <button onClick={openModal}>Open Modal</button> */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          {/* <button onClick={closeModal}>close</button> */}

          <div className="section1-reply">
            <div className="reply-headings">
              <h1 className="reply-h1-y">Reply to: {forum?.user}</h1>
            </div>

            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal} />
            </div>
          </div>
          <hr />
          <div>
            <div className="section-2-reply">
              <div className="text-area-modal-s">
                <textarea
                 value={name}
                 onChange={e => setName(e.target.value)}
                  className="section-2-replytext"
                  placeholder="Type your reply here"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className="upload-options2">
                <FileUploader
                  multiple={true}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  classes="drop_area"
                />
              </div>
            </div>
            <hr />
            <div className="section-2-reply-1">
              <textarea
                value={tags}
                onChange={e => setTags(e.target.value)}
                placeholder="Type one or more tag, comma separated "
                className="text-area-modal-2"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <hr />
            <div className="check-box-post-btn-s">
              <div>
                <button
                disabled={!name}
                  onClick={() => {
                    addReply();
                  }}
                  className="post-btn-dis">Send <AiOutlineSend/></button>
                <button
                  onClick={() => {
                    closeModal();
                  }}
                  className="post-btn-dis">Cancel <MdCancel/></button>
              </div>
            </div>
          </div>
        </Modal>

        <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[
          { src: forum?.Image },
        ]}
      />
      </div>
    </div>
  );
};

export default Replydata;
