/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./UserPlan.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import Footer from "../Footer/Footer ";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../Firebase/Firebase";
import { getDoc , doc} from "firebase/firestore";

const UserPlan = () => {

  

  const [user] = useAuthState(auth);

;

  const[getUser,setGetUser] = useState()

useEffect(()=> {

  getDoc(doc(db, 'usersLesson' , user?.uid ,  'MemberShip' , 'ContestPrepCertification')).then((docSnap)=> {
    if(docSnap.exists()) {
      setGetUser(docSnap.data())
    }
  })

},[])


  return (
    <div className="plan-bg">
      <div className=" body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
<div className=" grid-c1">

          <div className="tb-plan1 main-y grid-c2">
      
            <div className="tb-plan ">
              <div>
                <div>
                  <h1 className="plan">Account</h1>
                </div>

             
                <div>
                  <table className="tb-1">
                    <caption className="tb-cap">Payments</caption>
                    <thead>
                      <tr className="tr-plan">
                        <th className="th-plan" scope="col">
                          Membership
                        </th>
                        <th className="th-plan" scope="col">
                          Subscription
                        </th>
                        <th className="th-plan " scope="col">
                          Active
                        </th>
                        <th className="th-plan" scope="col">
                          Created
                        </th>
                        <th className="th-plan" scope="col">
                          Expires
                        </th>
                        <th className="th-plan" scope="col">
                          Payment Method.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-plan">
                        <td className="td-plan" data-label="  Membership">{getUser?.MemberShip}</td>
                        <td  className="td-plan"  data-label="     Subscription">{getUser?.Subscription}</td>
                        <td className="td-plan yes-1" data-label="Active ">{getUser?.Active === true ? (
                          "Yes"
                        ) : ( "No")}</td>
                        <td className="td-plan" data-label=" Created">{getUser?.createdAt.toDate().toLocaleDateString()}</td>
                        <td className="td-plan" data-label="Expires">{getUser?.Expires}</td>
                        <td  className="td-plan" data-label=" Payment Method"> {getUser?.PaymentMethod} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            
              </div>
           
         
            </div>
          </div>
            <div className="grid-c3">
              <Footer />
</div>
       
          </div>
        </div>

      </div>
    </div>
  );
};

export default UserPlan;
