import React from "react";
import {
  

  RouterProvider,
} from "react-router-dom";

import "./Style.scss";



import "./App.css";

import  { Toaster } from "react-hot-toast";

import { router } from "./Routes/Route/Route";

const App = () => {
  return (
    <>
    <RouterProvider router={router}></RouterProvider>
    <Toaster/>

    </>
  );
};

export default App;
