import React from "react";
import Footer from "../Footer/Footer ";
import Slider1 from "../../Features/Sidebar/Sidebar1";

const PrivacyPolicy = () => {
  return (
    <div className="bg-term">
    <div className=" body-fix-h">
      <div className="blog-flex-slider-data wrapper">
        <div>
          <Slider1 />
        </div>
        <div className=" main-y grid-c1">
          <div className="term-a1 grid-c2">
            <div className="term-a2">
              <div>
                <h1 className="term-h1">Privacy Policy</h1>
                <div className="term-a3">
                  <div>
                    <p className="term-p">
                      Thank you for choosing to be part of our community at
                       doing business as Tuff Trainer. We are
                      committed to protecting your personal information and
                      your right to privacy. If you have any questions or
                      concerns about this privacy notice, or our practices
                      with regards to your personal information, please
                      contact us at support@tufftrainer.in.com
                    </p>
                    <br />
                    <p className="term-p">
                      When you visit our website
                      https://www.tufftrainer.in(the "Website"), use
                      our mobile application, as the case may be (the "App")
                      and more generally, use any of our services (the
                      "Services", which include the Website and App), we
                      appreciate that you are trusting us with your personal
                      information. We take your privacy very seriously. In
                      this privacy notice, we seek to explain to you in the
                      clearest way possible what information we collect, how
                      we use it and what rights you have in relation to it. We
                      hope you take some time to read through it carefully, as
                      it is important. If there are any terms in this privacy
                      notice that you do not agree with, please discontinue
                      use of our Services immediately.
                    </p>
                    <p>
                      This privacy notice applies to all information collected
                      through our Services (which, as described above,
                      includes our Website and App), as well as, any related
                      services, sales, marketing or events.
                    </p>
                    <p>
                      Please read this privacy notice carefully as it will
                      help you understand what we do with the information that
                      we collect.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="term-a3">
                  <h1 className="term-h2">TABLE OF CONTENTS</h1>
                  <div>
                    <p className="term-p">
                      1. WHAT INFORMATION DO WE COLLECT?
                      <br />
                      2. HOW DO WE USE YOUR INFORMATION?
                      <br />
                      3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                      <br />
                      4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                      <br />
                      5. HOW LONG DO WE KEEP YOUR INFORMATION?
                      <br />
                      6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                      <br />
                      7. DO WE COLLECT INFORMATION FROM MINORS?
                      <br />
                      8. WHAT ARE YOUR PRIVACY RIGHTS?
                      <br />
                      9. CONTROLS FOR DO-NOT-TRACK FEATURES
                      <br />
                      10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                      RIGHTS?
                      <br />
                      11. DO WE MAKE UPDATES TO THIS NOTICE?
                      <br />
                      12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                      <br />
                      13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    1. WHAT INFORMATION DO WE COLLECT?
                  </h1>
                  <div>
                    <p className="term-p">
                      Personal information you disclose to us
                    </p>
                    <br />
                    <p>
                      In Short: We collect personal information that you
                      provide to us.
                    </p>
                    <br />
                    <p>
                      We collect personal information that you voluntarily
                      provide to us when you register on the Services, express
                      an interest in obtaining information about us or our
                      products and Services, when you participate in
                      activities on the Services (such as by posting messages
                      in our online forums or entering competitions, contests
                      or giveaways) or otherwise when you contact us.
                    </p>
                    <br />
                    <p>
                      The personal information that we collect depends on the
                      context of your interactions with us and the Services,
                      the choices you make and the products and features you
                      use. The personal information we collect may include the
                      following:
                    </p>
                    <br />
                    <p>
                      Personal Information Provided by You. We collect names;
                      phone numbers; email addresses; usernames; passwords;
                      and other similar information.
                    </p>
                    <br />
                    <p>
                      Payment Data. We may collect data necessary to process
                      your payment if you make purchases, such as your payment
                      instrument number (such as a credit card number), and
                      the security code associated with your payment
                      instrument. All payment data is stored by PayPal and
                      Stripe. You may find their privacy notice link(s) here:
                      https://www.paypal.com/uk/webapps/mpp/ua/privacy-full
                      and https://stripe.com/gb/privacy.
                    </p>
                    <br />
                    <p>
                      All personal information that you provide to us must be
                      true, complete and accurate, and you must notify us of
                      any changes to such personal information.
                    </p>
                    <br />
                    <p>Information automatically collected</p>
                    <br />
                    <p>
                      In Short: Some information — such as your Internet
                      Protocol (IP) address and/or browser and device
                      characteristics — is collected automatically when you
                      visit our Services.
                    </p>
                    <br />
                    <p>
                      We automatically collect certain information when you
                      visit, use or navigate the Services. This information
                      does not reveal your specific identity (like your name
                      or contact information) but may include device and usage
                      information, such as your IP address, browser and device
                      characteristics, operating system, language preferences,
                      referring URLs, device name, country, location,
                      information about how and when you use our Services and
                      other technical information. This information is
                      primarily needed to maintain the security and operation
                      of our Services, and for our internal analytics and
                      reporting purposes.
                    </p>
                    <br />
                    <p>
                      Like many businesses, we also collect information
                      through cookies and similar technologies.
                    </p>
                    <br />
                    <p>
                      The information we collect includes:  Log and Usage
                      Data. Log and usage data is service-related, diagnostic,
                      usage and performance information our servers
                      automatically collect when you access or use our
                      Services and which we record in log files. Depending on
                      how you interact with us, this log data may include your
                      IP address, device information, browser type and
                      settings and information about your activity in the
                      Services (such as the date/time stamps associated with
                      your usage, pages and files viewed, searches and other
                      actions you take such as which features you use), device
                      event information (such as system activity, error
                      reports (sometimes called 'crash dumps') and hardware
                      settings).  Device Data. We collect device data such as
                      information about your computer, phone, tablet or other
                      device you use to access the Services. Depending on the
                      device used, this device data may include information
                      such as your IP address (or proxy server), device and
                      application identification numbers, location, browser
                      type, hardware model Internet service provider and/or
                      mobile carrier, operating system and system
                      configuration information.
                    </p>
                    <br />
                    <p>Information collected through our App</p>
                    <br />
                    <p>
                      In Short: We collect information regarding your mobile
                      device, push notifications, when you use our App.
                    </p>
                    <br />
                    <p>
                      If you use our App, we also collect the following
                      information: Mobile Device Data. We automatically
                      collect device information (such as your mobile device
                      ID, model and manufacturer), operating system, version
                      information and system configuration information, device
                      and application identification numbers, browser type and
                      version, hardware model Internet service provider and/or
                      mobile carrier, and Internet Protocol (IP) address (or
                      proxy server). If you are using our App, we may also
                      collect information about the phone network associated
                      with your mobile device, your mobile device’s operating
                      system or platform, the type of mobile device you use,
                      your mobile device’s unique device ID and information
                      about the features of our App you accessed.
                    </p>
                    <br />
                    <p>
                      Push Notifications. We may request to send you push
                      notifications regarding your account or certain features
                      of the App. If you wish to opt-out from receiving these
                      types of communications, you may turn them off in your
                      device's settings. This information is primarily needed
                      to maintain the security and operation of our App, for
                      troubleshooting and for our internal analytics and
                      reporting purposes.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    2. HOW DO WE USE YOUR INFORMATION?
                  </h1>
                  <div>
                    <p className="term-p">
                      In Short: We process your information for purposes based
                      on legitimate business interests, the fulfillment of our
                      contract with you, compliance with our legal
                      obligations, and/or your consent.
                    </p>
                    <br />
                    <p>
                      We use personal information collected via our Services
                      for a variety of business purposes described below. We
                      process your personal information for these purposes in
                      reliance on our legitimate business interests, in order
                      to enter into or perform a contract with you, with your
                      consent, and/or for compliance with our legal
                      obligations. We indicate the specific processing grounds
                      we rely on next to each purpose listed below.
                    </p>
                    <br />
                    <p>
                      We use the information we collect or receive: To
                      facilitate account creation and logon process. If you
                      choose to link your account with us to a third-party
                      account (such as your Google or Facebook account), we
                      use the information you allowed us to collect from those
                      third parties to facilitate account creation and logon
                      process for the performance of the contract. To post
                      testimonials. We post testimonials on our Services that
                      may contain personal information. Prior to posting a
                      testimonial, we will obtain your consent to use your
                      name and the content of the testimonial. If you wish to
                      update, or delete your testimonial, please contact us at
                      contact@deltainfox.com and be sure to include your name,
                      testimonial location, and contact information. Request
                      feedback. We may use your information to request
                      feedback and to contact you about your use of our
                      Services. To enable user-to-user communications. We may
                      use your information in order to enable user-to-user
                      communications with each user's consent. To manage user
                      accounts. We may use your information for the purposes
                      of managing our account and keeping it in working order.
                      To send administrative information to you. We may use
                      your personal information to send you product, service
                      and new feature information and/or information about
                      changes to our terms, conditions, and policies. To
                      protect our Services. We may use your information as
                      part of our efforts to keep our Services safe and secure
                      (for example, for fraud monitoring and prevention). To
                      enforce our terms, conditions and policies for business
                      purposes, to comply with legal and regulatory
                      requirements or in connection with our contract. To
                      respond to legal requests and prevent harm. If we
                      receive a subpoena or other legal request, we may need
                      to inspect the data we hold to determine how to respond.
                      Fulfill and manage your orders. We may use your
                      information to fulfill and manage your orders, payments,
                      returns, and exchanges made through the Services.
                      Administer prize draws and competitions. We may use your
                      information to administer prize draws and competitions
                      when you elect to participate in our competitions. To
                      deliver and facilitate delivery of services to the user.
                      We may use your information to provide you with the
                      requested service. To respond to user inquiries/offer
                      support to users. We may use your information to respond
                      to your inquiries and solve any potential issues you
                      might have with the use of our Services. To send you
                      marketing and promotional communications. We and/or our
                      third-party marketing partners may use the personal
                      information you send to us for our marketing purposes,
                      if this is in accordance with your marketing
                      preferences. For example, when expressing an interest in
                      obtaining information about us or our Services,
                      subscribing to marketing or otherwise contacting us, we
                      will collect personal information from you. You can
                      opt-out of our marketing emails at any time (see the
                      "WHAT ARE YOUR PRIVACY RIGHTS?" below). Deliver targeted
                      advertising to you. We may use your information to
                      develop and display personalized content and advertising
                      (and work with third parties who do so) tailored to your
                      interests and/or location and to measure its
                      effectiveness.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </h1>
                  <div>
                    <p className="term-p">
                      In Short: We only share information with your consent,
                      to comply with laws, to provide you with services, to
                      protect your rights, or to fulfill business obligations.
                      We may process or share your data that we hold based on
                      the following legal basis: Consent: We may process your
                      data if you have given us specific consent to use your
                      personal information for a specific purpose. Legitimate
                      Interests: We may process your data when it is
                      reasonably necessary to achieve our legitimate business
                      interests. Performance of a Contract: Where we have
                      entered into a contract with you, we may process your
                      personal information to fulfill the terms of our
                      contract. Legal Obligations: We may disclose your
                      information where we are legally required to do so in
                      order to comply with applicable law, governmental
                      requests, a judicial proceeding, court order, or legal
                      process, such as in response to a court order or a
                      subpoena (including in response to public authorities to
                      meet national security or law enforcement requirements).
                      Vital Interests: We may disclose your information where
                      we believe it is necessary to investigate, prevent, or
                      take action regarding potential violations of our
                      policies, suspected fraud, situations involving
                      potential threats to the safety of any person and
                      illegal activities, or as evidence in litigation in
                      which we are involved. More specifically, we may need to
                      process your data or share your personal information in
                      the following situations: Business Transfers. We may
                      share or transfer your information in connection with,
                      or during negotiations of, any merger, sale of company
                      assets, financing, or acquisition of all or a portion of
                      our business to another company. Business Partners. We
                      may share your information with our business partners to
                      offer you certain products, services or promotions.
                      Offer Wall. Our App may display a third-party hosted
                      "offer wall." Such an offer wall allows third-party
                      advertisers to offer virtual currency, gifts, or other
                      items to users in return for the acceptance and
                      completion of an advertisement offer. Such an offer wall
                      may appear in our App and be displayed to you based on
                      certain data, such as your geographic area or
                      demographic information. When you click on an offer
                      wall, you will be brought to an external website
                      belonging to other persons and will leave our App. A
                      unique identifier, such as your user ID, will be shared
                      with the offer wall provider in order to prevent fraud
                      and properly credit your account with the relevant
                      reward. Please note that we do not control third-party
                      websites and have no responsibility in relation to the
                      content of such websites. The inclusion of a link
                      towards a third-party website does not imply an
                      endorsement by us of such website. Accordingly, we do
                      not make any warranty regarding such third-party
                      websites and we will not be liable for any loss or
                      damage caused by the use of such websites. In addition,
                      when you access any third-party website, please
                      understand that your rights while accessing and using
                      those websites will be governed by the privacy notice
                      and terms of service relating to the use of those
                      websites.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </h1>
                  <div>
                    <p className="term-p">
                      In Short: We may use cookies and other tracking
                      technologies to collect and store your information.
                      <br />
                      We may use cookies and similar tracking technologies
                      (like web beacons and pixels) to access or store
                      information. Specific information about how we use such
                      technologies and how you can refuse certain cookies is
                      set out in our Cookie Notice.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    5. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </h1>
                  <div>
                    <p className="term-p">
                      In Short: We keep your information for as long as
                      necessary to fulfill the purposes outlined in this
                      privacy notice unless otherwise required by law.
                      <br />
                      We will only keep your personal information for as long
                      as it is necessary for the purposes set out in this
                      privacy notice, unless a longer retention period is
                      required or permitted by law (such as tax, accounting or
                      other legal requirements). No purpose in this notice
                      will require us keeping your personal information for
                      longer than six (6) months past the termination of the
                      user's account.
                      <br />
                      When we have no ongoing legitimate business need to
                      process your personal information, we will either delete
                      or anonymize such information, or, if this is not
                      possible (for example, because your personal information
                      has been stored in backup archives), then we will
                      securely store your personal information and isolate it
                      from any further processing until deletion is possible.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </h1>
                  <div>
                    <p className="term-p">
                      In Short: We aim to protect your personal information
                      through a system of organizational and technical
                      security measures.
                      <br />
                      We have implemented appropriate technical and
                      organizational security measures designed to protect the
                      security of any personal information we process.
                      However, despite our safeguards and efforts to secure
                      your information, no electronic transmission over the
                      Internet or information storage technology can be
                      guaranteed to be 100% secure, so we cannot promise or
                      guarantee that hackers, cybercriminals, or other
                      unauthorized third parties will not be able to defeat
                      our security, and improperly collect, access, steal, or
                      modify your information. Although we will do our best to
                      protect your personal information, transmission of
                      personal information to and from our Services is at your
                      own risk. You should only access the Services within a
                      secure environment.
                    </p>
                  </div>
                </div>

                <div className="term-a3">
                  <h1 className="term-h2">
                    7. DO WE COLLECT INFORMATION FROM MINORS?
                  </h1>
                  <div>
                    <p className="term-p">
                      In Short: We aim to protect your personal information
                      through a system of organizational and technical
                      security measures.
                      <br />
                      We have implemented appropriate technical and
                      organizational security measures designed to protect the
                      security of any personal information we process.
                      However, despite our safeguards and efforts to secure
                      your information, no electronic transmission over the
                      Internet or information storage technology can be
                      guaranteed to be 100% secure, so we cannot promise or
                      guarantee that hackers, cybercriminals, or other
                      unauthorized third parties will not be able to defeat
                      our security, and improperly collect, access, steal, or
                      modify your information. Although we will do our best to
                      protect your personal information, transmission of
                      personal information to and from our Services is at your
                      own risk. You should only access the Services within a
                      secure environment.
                    </p>
                  </div>
                </div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    8. WHAT ARE YOUR PRIVACY RIGHTS?
                  </h1>
                  <div>
                    <p className="term-p">
                      IIn Short: In some regions, you have rights that allow
                      you greater access to and control over your personal
                      information. You may review, change, or terminate your
                      account at any time.
                      <br />
                      In some regions, you have certain rights under
                      applicable data protection laws. These may include the
                      right (i) to request access and obtain a copy of your
                      personal information, (ii) to request rectification or
                      erasure; (iii) to restrict the processing of your
                      personal information; and (iv) if applicable, to data
                      portability. In certain circumstances, you may also have
                      the right to object to the processing of your personal
                      information. To make such a request, please use the
                      contact details provided below. We will consider and act
                      upon any request in accordance with applicable data
                      protection laws.
                      <br />
                      If we are relying on your consent to process your
                      personal information, you have the right to withdraw
                      your consent at any time. Please note however that this
                      will not affect the lawfulness of the processing before
                      its withdrawal, nor will it affect the processing of
                      your personal information conducted in reliance on
                      lawful processing grounds other than consent.
                      <br />
                      If you have questions or comments about your privacy
                      rights, you may email us at contact@deltainfox.com.
                      <br />
                      <h1 className="term-h2">Account Information</h1>
                      <p>
                        If you would at any time like to review or change the
                        information in your account or terminate your account,
                        you can: Log in to your account settings and update
                        your user account. Contact us using the contact
                        information provided. Upon your request to terminate
                        your account, we will deactivate or delete your
                        account and information from our active databases.
                        However, we may retain some information in our files
                        to prevent fraud, troubleshoot problems, assist with
                        any investigations, enforce our Terms of Use and/or
                        comply with applicable legal requirements.
                      </p>
                      <p>
                        Cookies and similar technologies: Most Web browsers
                        are set to accept cookies by default. If you prefer,
                        you can usually choose to set your browser to remove
                        cookies and to reject cookies. If you choose to remove
                        cookies or reject cookies, this could affect certain
                        features or services of our Services. To opt-out of
                        interest-based advertising by advertisers on our
                        Services visit http://www.aboutads.info/choices/.
                      </p>
                      <p>
                        Opting out of email marketing: You can unsubscribe
                        from our marketing email list at any time by clicking
                        on the unsubscribe link in the emails that we send or
                        by contacting us using the details provided below. You
                        will then be removed from the marketing email list —
                        however, we may still communicate with you, for
                        example to send you service-related emails that are
                        necessary for the administration and use of your
                        account, to respond to service requests, or for other
                        non-marketing purposes. To otherwise opt-out, you may:
                         Access your account settings and update your
                        preferences.  Contact us using the contact
                        information provided.
                      </p>
                    </p>
                  </div>
                </div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    9. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </h1>
                  <div>
                    <p className="term-p">
                      Most web browsers and some mobile operating systems and
                      mobile applications include a Do-Not-Track ("DNT")
                      feature or setting you can activate to signal your
                      privacy preference not to have data about your online
                      browsing activities monitored and collected. At this
                      stage no uniform technology standard for recognizing and
                      implementing DNT signals has been finalized. As such, we
                      do not currently respond to DNT browser signals or any
                      other mechanism that automatically communicates your
                      choice not to be tracked online. If a standard for
                      online tracking is adopted that we must follow in the
                      future, we will inform you about that practice in a
                      revised version of this privacy notice.
                    </p>
                  </div>
                </div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    10 What categories of personal information do we collect?
                  </h1>
                  <div>
                    <p className="term-p">
                      We have collected the following categories of personal
                      information in the past twelve (12) months:
                      <br />
                      Category
                      <br />
                      Examples
                      <br />
                      Collected
                      <br />
                      A. Identifiers Contact details, such as real name,
                      alias, postal address, telephone or mobile contact
                      number, unique personal identifier, online identifier,
                      Internet Protocol address, email address and account
                      name YES
                      <br />
                      B. Personal information categories listed in the
                      California Customer Records statute Name, contact
                      information, education, employment, employment history
                      and financial information YES
                      <br />
                      C. Protected classification characteristics under
                      California or federal law Gender and date of birth YES
                      <br />
                      D. Commercial information Transaction information,
                      purchase history, financial details and payment
                      information YES
                      <br />
                      E. Biometric information Fingerprints and voiceprints
                      YES
                      <br />
                      F. Internet or other similar network activity Browsing
                      history, search history, online behavior, interest data,
                      and interactions with our and other websites,
                      applications, systems and advertisements NO
                      <br />
                      G. Geolocation data Device location YES
                      <br />
                      H. Audio, electronic, visual, thermal, olfactory, or
                      similar information Images and audio, video or call
                      recordings created in connection with our business
                      activities NO
                      <br />
                      I. Professional or employment-related information
                      Business contact details in order to provide you our
                      services at a business level, job title as well as work
                      history and professional qualifications if you apply for
                      a job with us NO
                      <br />
                      J. Education Information Student records and directory
                      information NO
                      <br />
                      K. Inferences drawn from other personal information
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual’s preferences and
                      characteristics NO
                      <br />
                      <p>
                        We may also collect other personal information outside
                        of these categories instances where you interact with
                        us in-person, online, or by phone or mail in the
                        context of: Receiving help through our customer
                        support channels; Participation in customer surveys or
                        contests; and Facilitation in the delivery of our
                        Services and to respond to your inquiries. How do we
                        use and share your personal information? More
                        information about our data collection and sharing
                        practices can be found in this privacy notice. You may
                        contact us by email at contact@deltainfox or by
                        referring to the contact details at the bottom of this
                        document. If you are using an authorized agent to
                        exercise your right to opt-out we may deny a request
                        if the authorized agent does not submit proof that
                        they have been validly authorized to act on your
                        behalf. Will your information be shared with anyone
                        else? We may disclose your personal information with
                        our service providers pursuant to a written contract
                        between us and each service provider. Each service
                        provider is a for-profit entity that processes the
                        information on our behalf. We may use your personal
                        information for our own business purposes, such as for
                        undertaking internal research for technological
                        development and demonstration. This is not considered
                        to be "selling" of your personal data. has not
                        disclosed or sold any personal information to third
                        parties for a business or commercial purpose in the
                        preceding 12 months. will not sell personal
                        information in the future belonging to website
                        visitors, users and other consumers. Your rights with
                        respect to your personal data Right to request
                        deletion of the data - Request to delete You can ask
                        for the deletion of your personal information. If you
                        ask us to delete your personal information, we will
                        respect your request and delete your personal
                        information, subject to certain exceptions provided by
                        law, such as (but not limited to) the exercise by
                        another consumer of his or her right to free speech,
                        our compliance requirements resulting from a legal
                        obligation or any processing that may be required to
                        protect against illegal activities. Right to be
                        informed - Request to know Depending on the
                        circumstances, you have a right to know: whether we
                        collect and use your personal information; the
                        categories of personal information that we collect;
                        the purposes for which the collected personal
                        information is used; whether we sell your personal
                        information to third parties; the categories of
                        personal information that we sold or disclosed for a
                        business purpose; the categories of third parties to
                        whom the personal information was sold or disclosed
                        for a business purpose; and the business or commercial
                        purpose for collecting or selling personal
                        information. In accordance with applicable law, we are
                        not obligated to provide or delete consumer
                        information that is de-identified in response to a
                        consumer request or to re-identify individual data to
                        verify a consumer request. Right to Non-Discrimination
                        for the Exercise of a Consumer’s Privacy Rights We
                        will not discriminate against you if you exercise your
                        privacy rights. Verification process Upon receiving
                        your request, we will need to verify your identity to
                        determine you are the same person about whom we have
                        the information in our system. These verification
                        efforts require us to ask you to provide information
                        so that we can match it with information you have
                        previously provided us. For instance, depending on the
                        type of request you submit, we may ask you to provide
                        certain information so that we can match the
                        information you provide with the information we
                        already have on file, or we may contact you through a
                        communication method (e.g. phone or email) that you
                        have previously provided to us. We may also use other
                        verification methods as the circumstances dictate. We
                        will only use personal information provided in your
                        request to verify your identity or authority to make
                        the request. To the extent possible, we will avoid
                        requesting additional information from you for the
                        purposes of verification. If, however, we cannot
                        verify your identity from the information already
                        maintained by us, we may request that you provide
                        additional information for the purposes of verifying
                        your identity, and for security or fraud-prevention
                        purposes. We will delete such additionally provided
                        information as soon as we finish verifying you. Other
                        privacy rights you may object to the processing of
                        your personal data you may request correction of your
                        personal data if it is incorrect or no longer
                        relevant, or ask to restrict the processing of the
                        data you can designate an authorized agent to make a
                        request under the CCPA on your behalf. We may deny a
                        request from an authorized agent that does not submit
                        proof that they have been validly authorized to act on
                        your behalf in accordance with the CCPA. you may
                        request to opt-out from future selling of your
                        personal information to third parties. Upon receiving
                        a request to opt-out, we will act upon the request as
                        soon as feasibly possible, but no later than 15 days
                        from the date of the request submission. To exercise
                        these rights, you can contact us by email at
                        support@tufftrainer.in.com, or by referring to the
                        contact details at the bottom of this document. If you
                        have a complaint about how we handle your data, we
                        would like to hear from you.
                      </p>
                    </p>
                  </div>
                </div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    11. DO WE MAKE UPDATES TO THIS NOTICE?
                  </h1>
                  <div>
                    <p className="term-p">
                      In Short: Yes, we will update this notice as necessary
                      to stay compliant with relevant laws.
                    </p>
                    <p>
                      We may update this privacy notice from time to time. The
                      updated version will be indicated by an updated
                      "Revised" date and the updated version will be effective
                      as soon as it is accessible. If we make material changes
                      to this privacy notice, we may notify you either by
                      prominently posting a notice of such changes or by
                      directly sending you a notification. We encourage you to
                      review this privacy notice frequently to be informed of
                      how we are protecting your information.
                    </p>
                  </div>
                </div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </h1>
                  <div>
                    <p className="term-p">
                      Delta Infox LLP
                      <br />
                      167 Khuda Ali Sher Sector 1 Chandigarh 160001
                      <br />
                      India
                      <br />
                      Contact@deltainfox.com
                    </p>
                  </div>
                </div>
                <div className="term-a3">
                  <h1 className="term-h2">
                    13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                  </h1>
                  <div>
                    <p className="term-p">
                      Delta Infox LLP Based on the applicable laws of your
                      country, you may have the right to request access to the
                      personal information we collect from you, change that
                      information, or delete it in some circumstances. To
                      request to review, update, or delete your personal
                      information, please submit a request form
                      by clicking here.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-c3">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default PrivacyPolicy;
