import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import Sidebar2 from "../../Features/Sidebar/Sidebar2";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { db } from "../../Firebase/Firebase";
import ReactPlayer from "react-player";
import { doc, getDoc } from "firebase/firestore";

import Footer2 from "../Footer/Footer2";
import { AuthContext } from "../../../context/AuthContext";
import Sidebar from "../../Features/Sidebar/Sidebar1";
import { ImBooks } from "react-icons/im";
const Dashboard = () => {
  const [IntroHindi, setIntroHindi] = useState();
  const [IntroEnglish, setIntroEnglish] = useState();
  const [keyFeatureHindi, setKeyFeatureHindi] = useState();
  const [keyFeatureEnglish, setKeyFeatureEnglish] = useState();
  const [whyCPA, setWhyCPA] = useState();

  const [aboutmentor1, setaboutmentor1] = useState();
  const [aboutmentor2, setaboutmentor2] = useState();
  const [aboutmentor3, setaboutmentor3] = useState();

  useEffect(() => {
    getDoc(doc(db, "AboutMentors", "AtinderjeetSingh")).then((docSnap) => {
      if (docSnap.exists()) {
        setaboutmentor1(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });

    getDoc(doc(db, "AboutMentors", "GuruAmeenAlai")).then((docSnap) => {
      if (docSnap.exists()) {
        setaboutmentor2(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });

    getDoc(doc(db, "AboutMentors", "RamanRattanRout")).then((docSnap) => {
      if (docSnap.exists()) {
        setaboutmentor3(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });
  }, []);

  useEffect(() => {
    getDoc(doc(db, "IntroVideos", "Hindi")).then((docSnap) => {
      if (docSnap.exists()) {
        setIntroHindi(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });

    getDoc(doc(db, "IntroVideos", "English")).then((docSnap) => {
      if (docSnap.exists()) {
        setIntroEnglish(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });

    getDoc(doc(db, "KeyFeatures", "English")).then((docSnap) => {
      if (docSnap.exists()) {
        setKeyFeatureEnglish(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });

    getDoc(doc(db, "KeyFeatures", "Hindi")).then((docSnap) => {
      if (docSnap.exists()) {
        setKeyFeatureHindi(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });

    getDoc(doc(db, "WhyCPA", "WhyCPA")).then((docSnap) => {
      if (docSnap.exists()) {
        setWhyCPA(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });
  }, []);

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [toggleState3, setToggleState3] = useState(1);
  const toggleTab3 = (index) => {
    setToggleState3(index);
  };

  const [toggleState4, setToggleState4] = useState(1);
  const toggleTab4 = (index) => {
    setToggleState4(index);
  };

  const [toggleState5, setToggleState5] = useState(1);
  const toggleTab5 = (index) => {
    setToggleState5(index);
  };

  const { user } = useContext(AuthContext);

  if (user) {
    return (
      <div className="dashboard-bg">
        <div className="body-fix-h">
          <div className="wrapper ">
            <div className="side-dash1">
              <div className="sidebar-home-d">
                <Sidebar />
              </div>
              <div className="grid-c1 main-y">
                <div className=" head-dash grid-c2">
                  <div className="dash-a1">
                    <div className="dash-a2">
                      {/* <div className="dash-logo-1">
                      <img src={require("../../Img/home-bg-1.jpg")} alt="" />
                    </div> */}
                      {/* <div>
                    <h1 className="dash-h1">Coaching Results</h1>
                    <p className="dash-p1">In Last Two Years Gold,Silver,Bronze</p>
                  </div> */}
                    </div>
                    <div className="dash-a16">
                      <div className="dash-a15">
                        <div className="dash-a6">
                          <h1>INTRODUCTION</h1>

                          {/* Tabs */}
                          <div className="dash-tabs">
                            <Tabs>
                              <TabList>
                                <div className="dash-7">
                                  <Tab>
                                    <button
                                      onClick={() => toggleTab(1)}
                                      className={
                                        toggleState === 1
                                          ? "dash-btn-1"
                                          : "dash-btn1"
                                      }
                                    >
                                      English
                                    </button>
                                  </Tab>
                                  <Tab>
                                    <button
                                      onClick={() => toggleTab(2)}
                                      className={
                                        toggleState === 2
                                          ? "dash-btn-2"
                                          : "dash-btn2"
                                      }
                                    >
                                      Hindi
                                    </button>
                                  </Tab>
                                </div>
                              </TabList>
                              {/* Intro Video English */}

                              <TabPanel>
                                <div className="dash-a5">
                                  <h1 className="dash-h2">
                                    Intro Video English
                                  </h1>
                                  <div className="dash-a4">
                                    <div className="dash-a3">
                                      <ReactPlayer
                                        className="i-f-dash-1 video-i-1"
                                        width="560"
                                        height="315"
                                        url={IntroEnglish?.url}
                                        title="Intro Video English"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        controls
                                        config={{
                                          file: {
                                            attributes: {
                                              controlsList: "nodownload",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                              {/* Intro Video Hindi */}

                              <TabPanel>
                                <div className="dash-a5">
                                  <h1 className="dash-h2">Intro Video Hindi</h1>
                                  <div className="dash-a4">
                                    <div className="dash-a3 ">
                                      <ReactPlayer
                                        className="i-f-dash-1 video-i-1"
                                        width="560"
                                        height="315"
                                        url={IntroHindi?.url}
                                        title="Intro Video Hindi"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        controls
                                        config={{
                                          file: {
                                            attributes: {
                                              controlsList: "nodownload",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            </Tabs>
                          </div>

                          <div className="aboutmentors dash-a6">
                            <h1>ABOUT MENTORS</h1>
                            <div className="dash-tabs">
                              <Tabs>
                                <TabList>
                                  <div className="dash-7">
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab5(1)}
                                        className={
                                          toggleState5 === 1
                                            ? "btn-1-a"
                                            : "btn-act1"
                                        }
                                      >
                                        Atinderjeet Singh
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab5(2)}
                                        className={
                                          toggleState5 === 2
                                            ? "btn-2-a"
                                            : "btn-act2"
                                        }
                                      >
                                        Guru Ameen Alai
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab5(3)}
                                        className={
                                          toggleState5 === 3
                                            ? "btn-3-a"
                                            : "btn-act3"
                                        }
                                      >
                                        Raman Rattan Rout
                                      </button>
                                    </Tab>
                                  </div>
                                </TabList>
                                {/* Intro Video English */}

                                <TabPanel>
                                  <div className="dash-a5">
                                    <div className="dash-a4">
                                      <div className="dash-a31">
                                        <ReactPlayer
                                          className="i-f-dash-2 video-i-1"
                                          width="560"
                                          height="315"
                                          url={aboutmentor1?.url}
                                          light={aboutmentor1?.img}
                                          title="About Mentors"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                                {/* Intro Video Hindi */}

                                <TabPanel>
                                  <div className="dash-a5">
                                    <div className="dash-a4">
                                      <div className="dash-a31 ">
                                        <ReactPlayer
                                          className="i-f-dash-2 video-i-1"
                                          width="560"
                                          height="315"
                                          light={aboutmentor2?.img}
                                          url={aboutmentor2?.url}
                                          title="About Mentors"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                                <TabPanel>
                                  <div className="dash-a5">
                                    <div className="dash-a4">
                                      <div className="dash-a31">
                                        <ReactPlayer
                                          className="i-f-dash-2 video-i-1"
                                          width="560"
                                          height="315"
                                          url={aboutmentor3?.url}
                                          light={aboutmentor3?.img}
                                          title="About Mentors"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                              </Tabs>
                            </div>
                          </div>

                          {/* Key Features*/}
                          <h1 className="dash-h12 keyy ">Key Features</h1>
                          <div>
                          <div class="grid">
                              <ul id="hexGrid">
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                      UNLIMITED COURSE UPDATES.
                                      </p>
                                    </p>
                                  </div>
                                </li>

                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                      LIFETIME ACCESS.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">BE A LIFETIME PART ONE OF THE BEST COACHING COMMUNITY.</p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                       SIMPLE AND EASY TO GRASP.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                       SEPARATE ENGLISH / HINDI LECTURES.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo3">
                                        FORUM SECTION FOR QUESTIONS AND ANSWERS.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">COVERED EVERY ASPECTS OF NUTRITION AND TRAINING IN DETAILS.</p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                        PRACTICAL ASPECTS OF FITNESS BUSINESS COVERED.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">LIVE QUESTIONS AND ANSWERS AND NEW TOPIC DISCUSSIONS SESSIONS .</p>
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div
                              style={{ marginTop: "30px" }}
                              className="dash-tabs"
                            >
                              <Tabs>
                                <TabList>
                                  <div className="dash-7">
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab4(1)}
                                        className={
                                          toggleState4 === 1
                                            ? "dash-btn-1"
                                            : "dash-btn1"
                                        }
                                      >
                                        English
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab4(2)}
                                        className={
                                          toggleState4 === 2
                                            ? "dash-btn-2"
                                            : "dash-btn2"
                                        }
                                      >
                                        Hindi
                                      </button>
                                    </Tab>
                                  </div>
                                </TabList>
                                {/* Intro Video English */}

                                <TabPanel>
                                  <div className="dash-a5">
                                    <h1 className="dash-h2">Key Features</h1>
                                    <div className="dash-a4">
                                      <div className="dash-a3">
                                        <ReactPlayer
                                          className="i-f-dash-1 video-i-1"
                                          width="560"
                                          height="315"
                                          url={keyFeatureEnglish?.url}
                                          title="Key Features English"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                                {/* Intro Video Hindi */}

                                <TabPanel>
                                  <div className="dash-a5">
                                    <h1 className="dash-h2">Key Features</h1>
                                    <div className="dash-a4">
                                      <div className="dash-a3 ">
                                        <ReactPlayer
                                          className="i-f-dash-1 video-i-1"
                                          width="560"
                                          height="315"
                                          url={keyFeatureHindi?.url}
                                          title="Key Features Hindi"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                              </Tabs>
                            </div>
                          </div>

                          {/*  */}

                          {/* Pricing cards */}
                          <div>
                            <h1 className="dash-h8">Fee Details</h1>

                            <div className="dash-a5">
                                  <h1 className="dash-h11">INR FEE DETAILS</h1>

                                  <Tabs>
                                    <TabList>
                                      <div className="dash-7">
                                        <Tab>
                                          <button
                                            onClick={() => toggleTab3(1)}
                                            className={
                                              toggleState3 === 1
                                                ? "dash-btn-1"
                                                : "dash-btn1"
                                            }
                                          >
                                            ONE TIME PAYMENT
                                          </button>
                                        </Tab>
                                        <Tab>
                                          <button
                                            onClick={() => toggleTab3(2)}
                                            className={
                                              toggleState3 === 2
                                                ? "dash-btn-2"
                                                : "dash-btn2"
                                            }
                                          >
                                            EMI
                                          </button>
                                        </Tab>
                                      </div>
                                    </TabList>
                                    {/* Intro Video English */}

                                    <TabPanel>
                                      <div className="dash-a5">
                                        <h1 className="dash-h11">
                                          ONE TIME Payment{" "}
                                        </h1>

                                        <div class="wrapper1">
                                          <div class="table-1 Ultimate">
                                            <div class="price-section-1">
                                              <div class="price-area-1">
                                                <div class="inner-area-1">
                                                  <span class="dash-text">
                                                    &#8377;
                                                  </span>
                                                  <span class="price">
                                                    30000
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="package-name"></div>
                                            <div class="features">
                                              <li>
                                                <span class="list-name">
                                                  INR 30000 (including GST
                                                  (18%))
                                                </span>
                                                <span class="icon check">
                                                  <i class="fas fa-check-circle"></i>
                                                </span>
                                              </li>

                                              <div class="btn">
                                                <button>Purchase</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </TabPanel>

                                    <TabPanel>
                                      <div>
                                        <div class="wrapper1">
                                          <div className="dash-a5">
                                            <h1 className="dash-h10">
                                              EMI details
                                            </h1>
                                            <div class="table-3 Ultimate">
                                              <div class="price-section-3">
                                                <div class="price-area-3">
                                                  <div class="inner-area-3">
                                                    <span class="dash-text">
                                                      &#8377;
                                                    </span>
                                                    <span class="price-3dash">
                                                  17500
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="package-name-3"></div>
                                              <div class="features-3">
                                                <li>
                                                  <span class="list-name">
                                                    2 EMI's of ₹17500
                                                  </span>
                                                  <span class="icon check">
                                                    <i class="fas fa-check-circle"></i>
                                                  </span>
                                                </li>
                                                <li>
                                                  <span class="list-name">
                                                    INR 35000 (including GST
                                                    (18%))
                                                  </span>
                                                  <span class="icon check">
                                                    <i class="fas fa-check-circle"></i>
                                                  </span>
                                                </li>

                                                <div class="btn">
                                                  <button>Purchase</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {/*  */}

                                        <div class="row example-split">
                                          <div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 emi-time-line-section">
                                            <ul class="timeline timeline-split">
                                              <li class="timeline-item">
                                                <div class="timeline-info">
                                                  <span>1ST EMI DETAILS</span>
                                                </div>
                                                <div class="timeline-marker"></div>
                                                <div class="timeline-content">
                                                  <h3 class="timeline-title">
                                                    After depositing the first
                                                    Emi you will get access to
                                                    1st and 2nd module.
                                                  </h3>
                                                </div>
                                              </li>
                                              <li class="timeline-item">
                                                <div class="timeline-info">
                                                  <span>2ST EMI DETAILS</span>
                                                </div>
                                                <div class="timeline-marker"></div>
                                                <div class="timeline-content">
                                                  <h3 class="timeline-title">
                                                    After depositing the 2nd Emi
                                                    you will get access to 3rd ,
                                                    4th  module.
                                                  </h3>
                                                </div>
                                              </li>

                                            
                                            </ul>
                                            <div class="col-md-12 example-title">
                                              <h2 className="h2-im-n">
                                                IMPORTANT NOTICE
                                              </h2>

                                              <p>
                                                If you fail to deposit the Emi's
                                                in the given time of period you
                                                will get a reminder mail and
                                                after that if you don't pay the
                                                Emi within a week we will freeze
                                                the membership and you will not
                                                be able access the course until
                                                unless you don't pay the
                                                remaining amount.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </TabPanel>
                                  </Tabs>
                                </div>
                          </div>

                          {/*Why Choose CPA */}
                          <div className="whychoosecpa dash-a6">
                            <h1>
                              Why CONTEST PREP ACADEMY is a long format course?
                            </h1>
                            <div className="dash-a4">
                              <div className="dash-a3 ">
                                <ReactPlayer
                                  className="i-f-dash-1 video-i-1"
                                  width="560"
                                  height="315"
                                  url={whyCPA?.url}
                                  title="Why Choose Contest Prep Academy"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowFullScreen
                                  controls
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-c3">
                  <Footer2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="dashboard-bg">
        <div className="body-fix-h">
          <div className="wrapper ">
            <div className="side-dash1">
              <div className="sidebar-home-d">
                <Sidebar2 />
              </div>
              <div className="grid-c1 main-y">
                <div className=" head-dash grid-c2">
                  <div className="dash-a1">
                    <div className="dash-a2">
                      {/* <div className="dash-logo-1">
                    <img src={require("../../Img/home-bg-1.jpg")} alt="" />
                    </div> */}
                      {/* <div>
                    <h1 className="dash-h1">Coaching Results</h1>
                    <p className="dash-p1">In Last Two Years Gold,Silver,Bronze</p>
                  </div> */}
                    </div>
                    <div className="dash-a16">
                      <div className="dash-a15">
                        <div className="dash-a6">
                          <h1>INTRODUCTION</h1>

                          {/* Tabs */}
                          <div className="dash-tabs">
                            <Tabs>
                              <TabList>
                                <div className="dash-7">
                                  <Tab>
                                    <button
                                      onClick={() => toggleTab(1)}
                                      className={
                                        toggleState === 1
                                          ? "dash-btn-1"
                                          : "dash-btn1"
                                      }
                                    >
                                      English
                                    </button>
                                  </Tab>
                                  <Tab>
                                    <button
                                      onClick={() => toggleTab(2)}
                                      className={
                                        toggleState === 2
                                          ? "dash-btn-2"
                                          : "dash-btn2"
                                      }
                                    >
                                      Hindi
                                    </button>
                                  </Tab>
                                </div>
                              </TabList>
                              {/* Intro Video English */}

                              <TabPanel>
                                <div className="dash-a5">
                                  <h1 className="dash-h2">
                                    Intro Video English
                                  </h1>
                                  <div className="dash-a4">
                                    <div className="dash-a3">
                                      <ReactPlayer
                                        className="i-f-dash-1 video-i-1"
                                        width="560"
                                        height="315"
                                        url={IntroEnglish?.url}
                                        title="Intro Video English"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        controls
                                        config={{
                                          file: {
                                            attributes: {
                                              controlsList: "nodownload",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                              {/* Intro Video Hindi */}

                              <TabPanel>
                                <div className="dash-a5">
                                  <h1 className="dash-h2">Intro Video Hindi</h1>
                                  <div className="dash-a4">
                                    <div className="dash-a3 ">
                                      <ReactPlayer
                                        className="i-f-dash-1 video-i-1"
                                        width="560"
                                        height="315"
                                        url={IntroHindi?.url}
                                        title="Intro Video Hindi"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        controls
                                        config={{
                                          file: {
                                            attributes: {
                                              controlsList: "nodownload",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            </Tabs>
                          </div>

                          <div className="aboutmentors dash-a6">
                            <h1>ABOUT MENTORS</h1>
                            <div className="dash-tabs">
                              <Tabs>
                                <TabList>
                                  <div className="dash-7">
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab5(1)}
                                        className={
                                          toggleState5 === 1
                                            ? "btn-1-a"
                                            : "btn-act1"
                                        }
                                      >
                                        Atinderjeet Singh
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab5(2)}
                                        className={
                                          toggleState5 === 2
                                            ? "btn-2-a"
                                            : "btn-act2"
                                        }
                                      >
                                        Guru Ameen Alai
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab5(3)}
                                        className={
                                          toggleState5 === 3
                                            ? "btn-3-a"
                                            : "btn-act3"
                                        }
                                      >
                                        Raman Rattan Rout
                                      </button>
                                    </Tab>
                                  </div>
                                </TabList>
                                {/* Intro Video English */}

                                <TabPanel>
                                  <div className="dash-a5">
                                    <div className="dash-a4">
                                      <div className="dash-a31">
                                        <ReactPlayer
                                          className="i-f-dash-2 video-i-1"
                                          width="560"
                                          height="315"
                                          url={aboutmentor1?.url}
                                          light={aboutmentor1?.img}
                                          title="About Mentors"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                                {/* Intro Video Hindi */}

                                <TabPanel>
                                  <div className="dash-a5">
                                    <div className="dash-a4">
                                      <div className="dash-a31 ">
                                        <ReactPlayer
                                          className="i-f-dash-2 video-i-1"
                                          width="560"
                                          height="315"
                                          light={aboutmentor2?.img}
                                          url={aboutmentor2?.url}
                                          title="About Mentors"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                                <TabPanel>
                                  <div className="dash-a5">
                                    <div className="dash-a4">
                                      <div className="dash-a31">
                                        <ReactPlayer
                                          className="i-f-dash-2 video-i-1"
                                          width="560"
                                          height="315"
                                          url={aboutmentor3?.url}
                                          light={aboutmentor3?.img}
                                          title="About Mentors"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                              </Tabs>
                            </div>
                          </div>

                          {/* Key Features*/}
                          <h1 className="dash-h12 keyy ">Key Features</h1>
                          <div>
                            <div class="grid">
                              <ul id="hexGrid">
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                      UNLIMITED COURSE UPDATES.
                                      </p>
                                    </p>
                                  </div>
                                </li>

                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                      LIFETIME ACCESS.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">BE A LIFETIME PART ONE OF THE BEST COACHING COMMUNITY.</p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                       SIMPLE AND EASY TO GRASP.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                       SEPARATE ENGLISH / HINDI LECTURES.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo3">
                                        FORUM SECTION FOR QUESTIONS AND ANSWERS.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">COVERED EVERY ASPECTS OF NUTRITION AND TRAINING IN DETAILS.</p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">
                                        PRACTICAL ASPECTS OF FITNESS BUSINESS COVERED.
                                      </p>
                                    </p>
                                  </div>
                                </li>
                                <li class="hex">
                                  <div class="hexIn">
                                    <p class="hexLink">
                                      <div class="hexa-im"></div>

                                      <p id="demo2">LIVE QUESTIONS AND ANSWERS AND NEW TOPIC DISCUSSIONS SESSIONS .</p>
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div
                              style={{ marginTop: "30px" }}
                              className="dash-tabs"
                            >
                              <Tabs>
                                <TabList>
                                  <div className="dash-7">
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab4(1)}
                                        className={
                                          toggleState4 === 1
                                            ? "dash-btn-1"
                                            : "dash-btn1"
                                        }
                                      >
                                        English
                                      </button>
                                    </Tab>
                                    <Tab>
                                      <button
                                        onClick={() => toggleTab4(2)}
                                        className={
                                          toggleState4 === 2
                                            ? "dash-btn-2"
                                            : "dash-btn2"
                                        }
                                      >
                                        Hindi
                                      </button>
                                    </Tab>
                                  </div>
                                </TabList>
                                {/* Intro Video English */}

                                <TabPanel>
                                  <div className="dash-a5">
                                    <h1 className="dash-h2">Key Features</h1>
                                    <div className="dash-a4">
                                      <div className="dash-a3">
                                        <ReactPlayer
                                          className="i-f-dash-1 video-i-1"
                                          width="560"
                                          height="315"
                                          url={keyFeatureEnglish?.url}
                                          title="Key Features English"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                                {/* Intro Video Hindi */}

                                <TabPanel>
                                  <div className="dash-a5">
                                    <h1 className="dash-h2">Key Features</h1>
                                    <div className="dash-a4">
                                      <div className="dash-a3 ">
                                        <ReactPlayer
                                          className="i-f-dash-1 video-i-1"
                                          width="560"
                                          height="315"
                                          url={keyFeatureHindi?.url}
                                          title="Key Features Hindi"
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                          config={{
                                            file: {
                                              attributes: {
                                                controlsList: "nodownload",
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                              </Tabs>
                            </div>
                          </div>
                          {/* curriculum - Details */}

                          <div>
                            <div>
                              <h1 className="curr-ha1">CURRICULUM CONTENT</h1>
                            </div>
                            <div className="c1a">
                              <div className="c2a">
                                <div className="card-container-h">
                                  <div className="mod-card-bb">
                                    <div className="card-mod1">
                                      <div>
                                        <ImBooks className="bb-svg" size={30} />
                                      </div>
                                      <div className="a1-h1">
                                        <h1>MODULE 1</h1>
                                      </div>
                                      <div className="a1-h2">
                                        <h1>COACHING MINDSET</h1>
                                      </div>
                                    </div>
                                    <div>
                                      <ul className="cad-ul">
                                        <li>
                                          NUTRITIONAL SCIENCE “NEED FOR THE
                                          MOMENT”{" "}
                                        </li>
                                        <li>
                                          COACH – A JOB AND A RESPONSIBILITY{" "}
                                        </li>
                                        <li>EVERYONE IS NOT SAME</li>
                                        <li>MAKING AND EFFECTIVE PLAN</li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="mod-card-bb">
                                    <div className="card-mod1">
                                      <div>
                                        <ImBooks className="bb-svg" size={30} />
                                      </div>
                                      <div className="a1-h1">
                                        <h1>MODULE 2</h1>
                                      </div>
                                      <div className="a1-h2">
                                        <h1>NUTRITION </h1>
                                      </div>
                                    </div>
                                    <div>
                                      <ul className="cad-ul">
                                        <li>ENERGY BALANCE</li>
                                        <li> MACRONUTRIENTS </li>
                                        <li>
                             
                                          MICRONUTRIENTS AND FLUID BALANCE
                                        </li>
                                        <li>MEALFREQUENCY AND TIMING </li>
                                        <li>DIET BREAK & REFEEDS</li>
                                        <li> GUT HEALTH</li>
                                        <li> FLEXIBLE DIETING </li>
                                        <li>REVERSE DIETING</li>
                                        <li> SUPPLEMENTATION</li>

                                        <li>MAKING A PERFECT NUTRITION PLAN</li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="mod-card-bb">
                                    <div className="card-mod1">
                                      <div>
                                        <ImBooks className="bb-svg" size={30} />
                                      </div>
                                      <div className="a1-h1">
                                        <h1>MODULE 3</h1>
                                      </div>
                                      <div className="a1-h2">
                                        <h1>TRAINING</h1>
                                      </div>
                                    </div>
                                    <div>
                                      <ul className="cad-ul">
                                        <li>THE MOST IMPORTANT THING ABOUT TRAINING – ADHERENCE </li>
                                        <li>WHAT IS HYPERTROPHY AND HYPERPLASIA 
</li>
                                        <li>VOLUME, INTESITY AND FREQUENCY</li>
                                        <li>EXERCISE SELECTION PATTERN – THE MOST SIMPLEST APPROACH 
</li>
                                        <li>MUSCLE BREAKDOWN AND GROWTH </li>

                                        <li>WHEN TO DELOAD </li>

                                        <li>MAKING A PERFECT TRAINING PLAN</li>

                                        <li>SPECIALIZATION TRAINING FOR WEEK BODY PARTS</li>

                                        <li>
                                          INSIGHTS OF SOME POPULAR TRAINING
                                          METHODS AND THEIR IMPLEMENTATION{" "}
                                        </li>

                                        <li>OVERLOAD </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="mod-card-bb">
                                    <div className="card-mod1">
                                      <div>
                                        <ImBooks className="bb-svg" size={30} />
                                      </div>
                                      <div className="a1-h1">
                                        <h1>MODULE 4</h1>
                                      </div>
                                      <div className="a1-h2">
                                        <h1>BUSINESS STRATEGIES </h1>
                                      </div>
                                    </div>
                                    <div>
                                      <ul className="cad-ul">
                                        <li>
                                       UNDERSTANDING THE POSSIBILITIES 
                                        </li>
                                        <li>SETTING THE GOALS</li>
                                        <li>50-25-25 METHOD </li>
                                        <li>
                                        SETTING UP THE MARKETING 
                                        </li>
                                        <li>GENERATING LEADS </li>
                                        <li>
                                        	SETTING UP YOUR OWN COACHING TEAM

                                        </li>
                                        <li>
                                    “SERVICE” ITS MORE DIFFICULT THEN SELLING 
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  */}

                          {/*  */}

                          {/* Pricing cards */}
                          <div>
                            
                            <h1 className="dash-h8">Fee Details</h1>


                 


                                    <Tabs>
                                    <TabList>
                                      <div className="dash-7">
                                        <Tab>
                                          <button
                                            onClick={() => toggleTab3(1)}
                                            className={
                                              toggleState3 === 1
                                                ? "dash-btn-1"
                                                : "dash-btn1"
                                            }
                                          >
                                            ONE TIME PAYMENT
                                          </button>
                                        </Tab>
                                        <Tab>
                                          <button
                                            onClick={() => toggleTab3(2)}
                                            className={
                                              toggleState3 === 2
                                                ? "dash-btn-2"
                                                : "dash-btn2"
                                            }
                                          >
                                            EMI
                                          </button>
                                        </Tab>
                                      </div>
                                    </TabList>
                                    {/* Intro Video English */}

                                    <TabPanel>
                                      <div className="dash-a5">
                                        <h1 className="dash-h11">
                                          ONE TIME Payment{" "}
                                        </h1>

                                        <div class="wrapper1">
                                          <div class="table-1 Ultimate">
                                            <div class="price-section-1">
                                              <div class="price-area-1">
                                                <div class="inner-area-1">
                                                  <span class="dash-text">
                                                    &#8377;
                                                  </span>
                                                  <span class="price">
                                                    30000
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="package-name"></div>
                                            <div class="features">
                                              <li>
                                                <span class="list-name">
                                                  INR 30000 (including GST
                                                  (18%))
                                                </span>
                                                <span class="icon check">
                                                  <i class="fas fa-check-circle"></i>
                                                </span>
                                              </li>

                                              <div class="btn">
                                                <button>Purchase</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </TabPanel>

                                    <TabPanel>
                                      <div>
                                        <div class="wrapper1">
                                          <div className="dash-a5">
                                            <h1 className="dash-h10">
                                              EMI details
                                            </h1>
                                            <div class="table-3 Ultimate">
                                              <div class="price-section-3">
                                                <div class="price-area-3">
                                                  <div class="inner-area-3">
                                                    <span class="dash-text">
                                                      &#8377;
                                                    </span>
                                                    <span class="price-3dash">
                                                      17500
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="package-name-3"></div>
                                              <div class="features-3">
                                                <li>
                                                  <span class="list-name">
                                                    2 EMI's of ₹17500
                                                  </span>
                                                  <span class="icon check">
                                                    <i class="fas fa-check-circle"></i>
                                                  </span>
                                                </li>
                                                <li>
                                                  <span class="list-name">
                                                    INR 35000 (including GST
                                                    (18%))
                                                  </span>
                                                  <span class="icon check">
                                                    <i class="fas fa-check-circle"></i>
                                                  </span>
                                                </li>

                                                <div class="btn">
                                                  <button>Purchase</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {/*  */}

                                        <div class="row example-split">
                                          <div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 emi-time-line-section">
                                            <ul class="timeline timeline-split">
                                              <li class="timeline-item">
                                                <div class="timeline-info">
                                                  <span>1ST EMI DETAILS</span>
                                                </div>
                                                <div class="timeline-marker"></div>
                                                <div class="timeline-content">
                                                  <h3 class="timeline-title">
                                                    After depositing the first
                                                    Emi you will get access to
                                                    1st and 2nd module.
                                                  </h3>
                                                </div>
                                              </li>
                                              <li class="timeline-item">
                                                <div class="timeline-info">
                                                  <span>2ST EMI DETAILS</span>
                                                </div>
                                                <div class="timeline-marker"></div>
                                                <div class="timeline-content">
                                                  <h3 class="timeline-title">
                                                    After depositing the 2nd Emi
                                                    you will get access to 3rd ,
                                                    4th  module.
                                                  </h3>
                                                </div>
                                              </li>

                                        
                                            </ul>
                                            <div class="col-md-12 example-title">
                                              <h2 className="h2-im-n">
                                                IMPORTANT NOTICE
                                              </h2>

                                              <p>
                                                If you fail to deposit the Emi's
                                                in the given time of period you
                                                will get a reminder mail and
                                                after that if you don't pay the
                                                Emi within a week we will freeze
                                                the membership and you will not
                                                be able access the course until
                                                unless you don't pay the
                                                remaining amount.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </TabPanel>
                                  </Tabs>


   <div>

                            {/* <Tabs>
                              <TabList>
                                <div className="dash-7">
                                  <Tab>
                                  <button
                                      onClick={() => toggleTab2(1)}
                                      className={
                                        toggleState2 === 1
                                          ? "dash-btn-1"
                                          : "dash-btn1"
                                      }
                                    >
                                      INR
                                    </button>
                                  </Tab>
                                  <Tab>
                                    <button
                                      onClick={() => toggleTab2(2)}
                                      className={
                                        toggleState2 === 2
                                          ? "dash-btn-2"
                                          : "dash-btn2"
                                      }
                                    >
                                      USD
                                    </button>
                                  </Tab>
                                </div>
                              </TabList>

                              <TabPanel>
                                <div className="dash-a5">
                                  <h1 className="dash-h11">INR FEE DETAILS</h1>

                                  <Tabs>
                                    <TabList>
                                      <div className="dash-7">
                                        <Tab>
                                          <button
                                            onClick={() => toggleTab3(1)}
                                            className={
                                              toggleState3 === 1
                                                ? "dash-btn-1"
                                                : "dash-btn1"
                                            }
                                          >
                                            ONE TIME PAYMENT
                                          </button>
                                        </Tab>
                                        <Tab>
                                          <button
                                            onClick={() => toggleTab3(2)}
                                            className={
                                              toggleState3 === 2
                                                ? "dash-btn-2"
                                                : "dash-btn2"
                                            }
                                          >
                                            EMI
                                          </button>
                                        </Tab>
                                      </div>
                                    </TabList>

                                    <TabPanel>
                                      <div className="dash-a5">
                                        <h1 className="dash-h11">
                                          ONE TIME Payment{" "}
                                        </h1>

                                        <div class="wrapper1">
                                          <div class="table-1 Ultimate">
                                            <div class="price-section-1">
                                              <div class="price-area-1">
                                                <div class="inner-area-1">
                                                  <span class="dash-text">
                                                    &#8377;
                                                  </span>
                                                  <span class="price">
                                                    30000
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="package-name"></div>
                                            <div class="features">
                                              <li>
                                                <span class="list-name">
                                                  INR 30000 (including GST
                                                  (18%))
                                                </span>
                                                <span class="icon check">
                                                  <i class="fas fa-check-circle"></i>
                                                </span>
                                              </li>

                                              <div class="btn">
                                                <button>Purchase</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </TabPanel>

                                    <TabPanel>
                                      <div>
                                        <div class="wrapper1">
                                          <div className="dash-a5">
                                            <h1 className="dash-h10">
                                              EMI details
                                            </h1>
                                            <div class="table-3 Ultimate">
                                              <div class="price-section-3">
                                                <div class="price-area-3">
                                                  <div class="inner-area-3">
                                                    <span class="dash-text">
                                                      &#8377;
                                                    </span>
                                                    <span class="price-3dash">
                                                      17500
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="package-name-3"></div>
                                              <div class="features-3">
                                                <li>
                                                  <span class="list-name">
                                                    3 EMI's of ₹17500
                                                  </span>
                                                  <span class="icon check">
                                                    <i class="fas fa-check-circle"></i>
                                                  </span>
                                                </li>
                                                <li>
                                                  <span class="list-name">
                                                    INR 35000 (including GST
                                                    (18%))
                                                  </span>
                                                  <span class="icon check">
                                                    <i class="fas fa-check-circle"></i>
                                                  </span>
                                                </li>

                                                <div class="btn">
                                                  <button>Purchase</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                

                                        <div class="row example-split">
                                          <div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 emi-time-line-section">
                                            <ul class="timeline timeline-split">
                                              <li class="timeline-item">
                                                <div class="timeline-info">
                                                  <span>1ST EMI DETAILS</span>
                                                </div>
                                                <div class="timeline-marker"></div>
                                                <div class="timeline-content">
                                                  <h3 class="timeline-title">
                                                    After depositing the first
                                                    Emi you will get access to
                                                    1st and 2nd module.
                                                  </h3>
                                                </div>
                                              </li>
                                              <li class="timeline-item">
                                                <div class="timeline-info">
                                                  <span>2ST EMI DETAILS</span>
                                                </div>
                                                <div class="timeline-marker"></div>
                                                <div class="timeline-content">
                                                  <h3 class="timeline-title">
                                                    After depositing the 2nd Emi
                                                    you will get access to 3rd ,
                                                    4th and 5th module.
                                                  </h3>
                                                </div>
                                              </li>

                                              <li class="timeline-item">
                                                <div class="timeline-info">
                                                  <span>3RD EMI DETAILS</span>
                                                </div>
                                                <div class="timeline-marker"></div>
                                                <div class="timeline-content">
                                                  <h3 class="timeline-title">
                                                    After depositing the last
                                                    Emi you will get access to
                                                    the whole course.
                                                  </h3>
                                                </div>
                                              </li>
                                            </ul>
                                            <div class="col-md-12 example-title">
                                              <h2 className="h2-im-n">
                                                IMPORTANT NOTICE
                                              </h2>

                                              <p>
                                                If you fail to deposit the Emi's
                                                in the given time of period you
                                                will get a reminder mail and
                                                after that if you don't pay the
                                                Emi within a week we will freeze
                                                the membership and you will not
                                                be able access the course until
                                                unless you don't pay the
                                                remaining amount.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </TabPanel>
                                  </Tabs>
                                </div>
                              </TabPanel>

                              <TabPanel>
                                <div class="wrapper1">
                                  <div className="dash-a5">
                                    <h1 className="dash-h10">
                                      USD FEE DETAILS
                                    </h1>
                                    <div class="table-2 Ultimate">
                                      <div class="price-section-2">
                                        <div class="price-area-2">
                                          <div class="inner-area-2">
                                            <span class="dash-text">$</span>
                                            <span class="price-2dash">750</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="package-name-2"></div>
                                      <div class="features-2">
                                        <li>
                                          <span class="list-name">
                                            ONE TIME PAYMENT ( NO EMI)
                                          </span>
                                          <span class="icon check">
                                            <i class="fas fa-check-circle"></i>
                                          </span>
                                        </li>
                                        <div class="btn">
                                          <button>Purchase</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            </Tabs> */}
   </div>

                          </div>

                          {/*Why Choose CPA */}
                          <div className="whychoosecpa dash-a6">
                            <h1>
                              Why CONTEST PREP ACADEMY is a long format course?
                            </h1>
                            <div className="dash-a4">
                              <div className="dash-a3 ">
                                <ReactPlayer
                                  className="i-f-dash-1 video-i-1"
                                  width="560"
                                  height="315"
                                  url={whyCPA?.url}
                                  title="Why Choose Contest Prep Academy"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowFullScreen
                                  controls
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-c3">
                  <Footer2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Dashboard;
