import React from "react";
import './CaseStudyDetails.css'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";


import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../Features/Sidebar/Sidebar1";
import Footer from "../../Footer/Footer ";
import Image1 from './RON/7618255.jpg'
import Image2 from './RON/WhatsApp Image 2023-02-24 at 11.51.53.jpg'
import Image3 from './RON//WhatsApp Image 2023-02-24 at 11.51.54.jpg'
import Image4 from './RON/RON-3.jpg'
import Image5 from './RON/RON-4.jpg'
import { useEffect } from "react";



const CaseStudyDetails = () => {

const navigate  = useNavigate()
const {id} = useParams();






  return (
    <div className="case-c">
    <div className="body-fix-h">
<div className="wrapper ">
<div className="sidebar-home-d">
<Sidebar/>
</div>
<div className="grid-c1 main-y">
<div className="section1-im1 grid-c2  ">
  <div className="img-hh-1">


<div style={{textAlign:'center'}}>

<div className="contact-us-form">
<div className="imageScrollCaseStudy">
<img  src={Image1} alt="" />
<img  src={Image2} alt="" /><img  src={Image3} alt="" /><img  src={Image4} alt="" /><img  src={Image5} alt="" />
<div className="h1swipe">
</div>
</div>
</div>
</div>


</div>

  </div>




  
<div className="grid-c3">
              <Footer />
            </div>
</div>
</div>
</div>
</div>
    


  );
};

export default CaseStudyDetails;
